import { MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem } from "@mui/material";
import Menus from "Components/Shared/menu";
import { useDailog, useMenu } from "Utils/hooks";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import UpdateIcon from '@mui/icons-material/Update';
import { BdcMenuFunc } from "./type";
import ExchangeFeePayment from "./exchangeFeeBalance";
import PaymentDetails from "./paymentDetails";
import BdcFeeDialog from "Components/BdcFee/BdcFeeDialog";

const BdcMenu: BdcMenuFunc = (props) => {
    const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
    const exchangeDialog = useDailog()
    const paymentDialog = useDailog()
    const bdcUpdateDialog = useDailog()
    const apiEndpoint = "/bdc/paid-fee";

    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVert />
            </IconButton>

            <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
                <div>
                    <MenuItem onClick={exchangeDialog.openDialog}>
                        <AcUnitIcon /> View Exchange Fee Balance
                    </MenuItem>
                    <MenuItem onClick={paymentDialog.openDialog}>
                        <AcUnitIcon /> View Payment Details
                    </MenuItem>
                    <MenuItem onClick={bdcUpdateDialog.openDialog}>
                        <UpdateIcon /> Update BDC Payment
                    </MenuItem>
                </div>
            </Menus>

            <ExchangeFeePayment
                isOpen={exchangeDialog.isOpen}
                closeDialog={exchangeDialog.closeDialog}
                bdcInfo={{exchangeFeeBalance:props?.bdcInfo.exchangeFeeBalance, _id: props.bdcInfo._id}}
            />
            
            <PaymentDetails
                isOpen={paymentDialog.isOpen}
                closeDialog={paymentDialog.closeDialog}
                bdcInfo={{paymentDetails:props?.bdcInfo.paymentDetails, bdcId: props.bdcInfo._id}}
            />

            <BdcFeeDialog
                apiEndpoint={apiEndpoint}
                isOpen={bdcUpdateDialog.isOpen}
                closeDialog={bdcUpdateDialog.closeDialog}
                bdcInfo={props.bdcInfo}
               
            />

        </>
    )
}

export default BdcMenu