import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateKYCtypeFunc } from "./type";

const UpdateKYCtype: UpdateKYCtypeFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  kyc,
  refetch,
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/kyc-type/${kyc.id}`,
        data: values,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }
  const kycType = ["document", "text", "date"];
  const verifiers = ["monirates", "persona", "prembly", "safeHaven"];

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${kyc.name}  kyc`}
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="text"
              label="KYC Name"
              name="name"
              defaultValue={kyc.name}
              onChange={getData}
              required
            />
            <InputField
              type="text"
              label="KYC field"
              name="field"
              defaultValue={kyc.field}
              onChange={getData}
              required
            />

            <FormControl fullWidth>
              <InputLabel>KYC type *</InputLabel>
              <Select
                name="type"
                labelId="type"
                label="KYC type"
                value={values.type || kyc.type}
                onChange={(e: any) => getData(e)}
                required
              >
                {kycType?.map((type: string, index: number) => (
                  <MenuItem key={`${index}-type`} value={type} id={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>KYC Verifier *</InputLabel>
              <Select
                name="verifier"
                labelId="verifier"
                label="KYC verifier"
                value={values.verifier || kyc.verifier}
                onChange={(e: any) => getData(e)}
                required
              >
                {verifiers?.map((verifier: string, index: number) => (
                  <MenuItem
                    key={`${index}-verifier`}
                    value={verifier}
                    id={verifier}
                  >
                    {verifier}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ActionButton type="submit" variant="contained">
              Update KYC{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default UpdateKYCtype;
