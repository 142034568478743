import axios from "axios";
import { PostRequestInt, RequestResponseInt } from "./interface";
const baseUrl = process.env.REACT_APP_API_URL;

export const request = {
  post: async ({
    url,
    data,
    method = "POST",
  }: PostRequestInt): Promise<RequestResponseInt> => {
    const headers = { authorization: session.get("token") };
    try {
      const response = await axios({
        method,
        url: baseUrl + url,
        headers,
        data,
      });

      return response.data;
    } catch (err: any) {
      const data = err.response.data;
      throw { message: data.message, statusCode: data.httpStatusCode };
    }
  },
  get: async ({ url, method = "GET" }: { url: string; method?: string }) => {
    try {
      const headers = { authorization: session.get("token") };
      const response = await axios({
        url: baseUrl + url,
        headers,
        method,
        // data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url: string) => await request.get({ url, method: "DELETE" }),
  patch: async (params: PostRequestInt) =>
    await request.post({ ...params, method: "PATCH" }),
};

export const session = {
  get: (key: string) => {
    let value = localStorage.getItem(key);
    return parseJSON(value);
  },
  set: (key: string, value: any) => {
    value = JSON.stringify(value);
    localStorage.setItem(key, value);
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};

export const clientLogout = () => {
  session.remove("user");
  session.remove("token");
  window.location.reload();
};

export const handleError = (err: any) => {
  if (err?.name === "AxiosError") {
    const { data } = err.response;
    err.message = data?.message || "Something went wrong with your request";
    err.statusCode = data?.httpStatusCode || 500;
  }
  const statusCode = err.statusCode || 500;
  const message = err.message || "Something went wrong";
  return { message, statusCode };
};

export const parseJSON = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const getQueryString = (queryKeys: Record<string, any>) => {
  let query = "?";
  Object.keys(queryKeys).forEach((key) => {
    if (queryKeys[key]) {
      if (query === "?") query = `${query}${key}=${queryKeys[key]}`;
      else query = `${query}&${key}=${queryKeys[key]}`;
    }
  });
  return query;
};

export function toLocaleDate(isoDateString: string) {
  const date = new Date(isoDateString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  const readableDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return readableDate;
}

export function toLocaleDateOnly(isoDateString: string) {
  const date = new Date(isoDateString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const readableDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return readableDate;
}

export async function getUserData(userId: string) {
  const { data } = await request.get({
    url: `/user/${userId}`,
  });
  return data;
}
