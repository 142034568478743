import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { request, toLocaleDateOnly } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import * as XLSX from "xlsx"; // Import SheetJS library

const GetBdcFee = () => {
  const location = useLocation();
  const { currency, bdcId } = location.state || {};
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [bdcLists, setBdcLists] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const { mutate, isLoading, error } = useMutation(
    async () => {
      let url = `/bdc/exchange-fee-history?toCurrency=${currency}&bdcId=${bdcId}`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
      const { data } = await request.get({ url });
      return data;
    },
    {
      onSuccess: (data) => {
        const formattedData = data.map((list: any, index: number) => ({
          index: ++index + limit * (pageId - 1),
          bdcName: list.bdcName,
          currency: list.currency,
          fromCurrency: list.fromCurrency,
          toCurrency: list.toCurrency,
          amount: `${list.fromCurrency} ${list.amount}`,
          bdcFee: `${list.toCurrency} ${list.bdcFee}`,
          _id: list._id,
          type: list.type,
          reference: list.globalId,
          createdAt: toLocaleDateOnly(list.createdAt)
        }));
        setBdcLists(formattedData);
      }
    }
  );

  useEffect(() => {
    if (currency && bdcId) {
      mutate(); // Trigger the API call when toCurrency and bdcId are available
    }
  }, [currency, bdcId]);

  const handleFilter = () => {
    mutate(); // Trigger API call with updated filters
  };

  const handleDownload = () => {
    if (bdcLists.length === 0) {
      alert("No data available to download!");
      return;
    }

    // Prepare the worksheet data
    const headerRow = [`Name: ${bdcLists[0]?.bdcName || "Unknown"}`]; // Add BDC Name as a header row
    const columnHeaders = columns.map((col) => col.name); // Extract column names
    const dataRows = bdcLists.map(
      (row) => columns.map((col) => row[col.key]) // Extract data for each column
    );

    // Combine header and data
    const worksheetData = [headerRow, columnHeaders, ...dataRows];

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create the workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BDC Fee History");

    // Write the file
    XLSX.writeFile(workbook, "bdc_fee_history.xlsx");
  };

  const columns = [
    { minWidth: 20, name: "S/N", key: "index" },

    { minWidth: 30, name: "Order reference", key: "reference" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Fee", key: "bdcFee" },
    { minWidth: 30, name: "Type", key: "type" },
    { minWidth: 50, name: "Created At", key: "createdAt" }
  ];

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Container maxWidth="xl" sx={{ height: "85.5vh" }}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h6" component="div">
            {bdcLists[0]?.bdcName || "BDC Name"} {/* Display the BDC Name */}
          </Typography>
          <Box display="flex" gap={2}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={startDate || ""}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={endDate || ""}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Apply Filter
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Box>
        {bdcLists?.length ? (
          <StickyHeadTable
            data={bdcLists}
            dataLength={bdcLists?.length}
            columns={columns}
            setLimit={setLimit}
            limit={limit}
            setPageId={setPageId}
            pageId={pageId}
          />
        ) : (
          <NoContentFound />
        )}
      </Container>
    </Wrapper>
  );
};

export default GetBdcFee;
